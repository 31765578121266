import React from 'react';
import Image from 'react-bootstrap/Image';
import * as styles from './carouselHome.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import Kundendienst from '../../images/home/original/Kundendienst.jpg';
import Car1 from '../../images/home/car1.jpeg';
import Car2 from '../../images/home/car2.jpeg';
import Team2023 from '../../images/team/team_2023.jpeg';

const CarouselHome = () => {
  return (
    <>
      <Carousel fade interval={3000} controls={false} indicators={false}>
        <Carousel.Item className={`${styles.carouselItem} `}>
          <Image src={Team2023} className={`${styles.carouselImg}`} />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carouselItem}`}>
          <Image src={Car1} className={`${styles.carouselImg}`} />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carouselItem}`}>
          <Image src={Kundendienst} className={`${styles.carouselImg}`} />
        </Carousel.Item>
        <Carousel.Item className={`${styles.carouselItem}`}>
          <Image src={Car2} className={`${styles.carouselImg}`} />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default CarouselHome;
