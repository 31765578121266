import React, { useEffect, useState } from 'react';
import * as homePageStyles from './homePage.module.scss';
import CarouselHome from '../CarouselHome/carouselHome';

const HomePage = () => {
  const [fadeIn, setFadeIn] = useState(false);

  // Trigger fade-in on component mount
  useEffect(() => {
    setTimeout(() => setFadeIn(true), 500); // Delay to make the fade-in more noticeable
  }, []);

  return (
    <>
      <div className={`container-fluid ${homePageStyles.homeAnnoucement}`}>
        {/* <div className={`row`}>
          <h3>
            Betriebsferien vom 27.12.2022 bis 30.12.2022 - Gerne bedienen wir
            Sie wieder ab dem 3.1.2023
          </h3>
        </div> */}
      </div>
      <div className={`${homePageStyles.homeContainer}`}>
        <div className={`col-md-12 col-lg-7`}>
          <CarouselHome />
        </div>
        <div className={`col-md-12 col-lg-4 ${homePageStyles.rightBox}`}>
          <h1>
            Willkommen bei <br />
            <span className='yellow'>Bowman Automobile AG</span>
          </h1>
          <div className={`mt-2 mb-2`}>
            <div className={`${homePageStyles.buttonContainer}`}>
              <a href='https://bowman.sopl.ch/' target='_blank'>
                <button className='button-md align-self-center'>
                  Jetzt Termin vereinbaren
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
