// extracted by mini-css-extract-plugin
export var buttonMd = "carouselHome-module--button-md--b7c15";
export var carousel = "carouselHome-module--carousel--64382";
export var carouselImg = "carouselHome-module--carouselImg--a5a6e";
export var carouselInner = "carouselHome-module--carousel-inner--62e98";
export var carouselItem = "carouselHome-module--carouselItem--2307c";
export var grey = "carouselHome-module--grey--293f2";
export var greyFont = "carouselHome-module--greyFont--bee18";
export var headerContainer = "carouselHome-module--headerContainer--3e846";
export var spanBold = "carouselHome-module--spanBold--43eca";
export var yellow = "carouselHome-module--yellow--28c1f";