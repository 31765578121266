import React from 'react';
import '../styles/index.scss';
import Layout from '../components/layout';
import HomePage from '../components/HomePage/homePage';
import SEO from '../components/SEO/SEO';

const Home = () => {
  return (
    <>
      <Layout>
        <SEO
          title='Bowman Automobile AG'
          description=' Renault Partner in Schinznach-Dorf. Auto Reparaturen und Service. Verkauf von Neuwagen, An- und Verkauf von Occasionen.'
        />
        <HomePage />
      </Layout>
    </>
  );
};

export default Home;
